import React from "react";
import { Box, Card, CardContent, Typography, Button } from "@mui/material";
import FrankenCatBowlLoadCellChart from "../common/FrankenCatBowlEventLoadCellChart";

const FrankenCatBowlEventLoadCellChart = ({ eventId, sessionStartTime, apiUrl, source }) => {

  const [newEventModalIsOpen, setEventModalIsOpen] = React.useState(false);
  const [boxHeight, setboxHeight] = React.useState("");
  const height = 1670

  function handleDataFromChild(data) {
    setboxHeight("No Data");
  }

  return (
    <Box sx={{ mb: 3 }}>
      <Typography variant="h6" fontWeight="bold" sx={{ mb: 2 }}>
        Sensor Data
      </Typography>
      <Card>
        <CardContent>
          {boxHeight != "No Data" ?(
            <Box sx={{ height: {height} }}>
            <FrankenCatBowlLoadCellChart eventId={eventId} startTime={sessionStartTime} apiUrl={apiUrl} source={source} sendDataToParent={handleDataFromChild}/>
          </Box>
          ):
          <Box sx={{ height: 50 }}>
            <FrankenCatBowlLoadCellChart eventId={eventId} startTime={sessionStartTime} apiUrl={apiUrl} source={source} sendDataToParent={handleDataFromChild}/>
          </Box>
          }
          
        </CardContent>
      </Card>
    </Box>
  );
};
export default FrankenCatBowlEventLoadCellChart;
