import React from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";
import CatFeederEventLoadCellChart from "../common/CatFeederEventLoadCellChart";

const CatFeederLoadCellChart = ({ eventId, sessionStartTime }) => {
  return (
    <Box sx={{ mb: 3 }}>
      <Typography variant="h6" fontWeight="bold" sx={{ mb: 2 }}>
        Sensor Data Chart
      </Typography>
      <Card>
        <CardContent>
          <Box sx={{ height: 1250 }}>
            <CatFeederEventLoadCellChart eventId={eventId} startTime={sessionStartTime} />
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};
export default CatFeederLoadCellChart;
