import React, {useEffect} from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Checkbox,
    IconButton,
    Paper,
    Button,
    Box,
    Select,
    Menu,
    MenuItem,
    Chip,
    Typography
} from "@mui/material";
import { styled } from "@mui/system";
import { Link, useSearchParams, useParams } from "react-router-dom";
import Highlighter from "react-highlight-words";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import moment from 'moment-timezone';
import { Time } from "../../Timezone/component";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { findChunksAtEndOfString } from "../../../utils/findChunksAtEndOfString";

// const Time = ({ children }) => {
//     return <span>{moment(children).format("LLL")}</span>;
// };

const NoData = styled('div')({
    opacity: 0.4,
    fontStyle: "italic",
});

const Ul = styled('ul')({
    margin: 0,
    paddingLeft: "1rem",
});

const ActionIconButton = styled(IconButton)({
    padding: 0,
});

const StyledTableCell = styled(TableCell)({
    fontWeight: 'bold',
    backgroundColor: '#f5f5f5',
});

const FullWidthPaper = styled(Paper)({
    width: '100%',
    overflowX: 'auto',
});

const FullWidthTableContainer = styled(TableContainer)({
    width: '100%',
});

const CustomPagination = styled(Box)({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '10px',
    borderTop: '1px solid #e0e0e0',
    backgroundColor: '#fafafa',
});

const CustomTablePagination = (props) => {
    const {
        count,
        page,
        rowsPerPage,
        onPageChange,
        onRowsPerPageChange,
        rowsPerPageOptions,
        labelDisplayedRows,
        labelRowsPerPage,
        pagination
    } = props;

    return (
        <CustomPagination>
            <Box display="flex" alignItems="center">
                <Box>{labelRowsPerPage}</Box>
                <Select
                    value={rowsPerPage}
                    onChange={onRowsPerPageChange}
                    variant="outlined"
                    size="small"
                    style={{ marginLeft: 8, marginRight: 16 }}
                >
                    {rowsPerPageOptions.map((rowsPerPageOption) => (
                        <MenuItem key={rowsPerPageOption} value={rowsPerPageOption}>
                            {rowsPerPageOption}
                        </MenuItem>
                    ))}
                </Select>
                <Box>{labelDisplayedRows({ from: page * rowsPerPage + 1, to: Math.min(count, (page + 1) * rowsPerPage), count })}</Box>
            </Box>
            <Box display="flex" alignItems="center" marginLeft={2}>
                <IconButton
                    onClick={(event) => onPageChange(event, page - 1)}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    <ArrowBackIosIcon />
                </IconButton>
                <IconButton
                    onClick={(event) => onPageChange(event, page + 1)}
                    disabled={page >= Math.ceil(pagination.total / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    <ArrowForwardIosIcon />
                </IconButton>
            </Box>
        </CustomPagination>
    );
};

const SORT_FIELDS = {
    sn: "SN",
    name: "NAME",
    power: "BATTERY_PERCENTAGE",
    mostRecentUpload: "MOST_RECENT_UPLOAD_AT",
    stFirmwareRevision: "ST_FIRMWARE_REVISION",
    espFirmwareRevision: "ESP_FIRMWARE_REVISION",
    hardwareRevision: "HARDWARE_REVISION",
    wifiRssi: "WIFI_RSSI",
    firstConnectedAt: "FIRST_CONNECTED_AT",
    snFirstConnectedAt: "SN_FIRST_CONNECTED_AT",
    frozenAt: "FROZEN_AT",
};

const SORT_DIRS = {
    ascend: "ASC",
    descend: "DESC",
};

const useStyles = styled({
    monitorNameAndEdit: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "4px",
    },
});

const CatFeederEventTable = ({
    data = [],
    loading = false,
    columnsFn,
    pagination,
    searchParams,
    setSearchParams,
    updateParentState,
    isupdateTable,
    revisionOptions: {
        existingStFirmwareRevisions = [],
        existingEspFirmwareRevisions = [],
        existingHardwareRevisions = [],
    } = {},
    ...props
}) => {
    const styles = useStyles();
    const queryParameters = new URLSearchParams(window.location.search)
    const modal_value = queryParameters.get("modal_value")
    const timezone = 'UTC';  // Assuming a timezone value, replace it with your logic

    const formatDateBySelectedTimezone = (date) =>
        moment(date + "Z")
            .tz(timezone)
            .format("YYYY-MM-DDTHH:mm:ss Z");

    const getDirectionFromSortString = (sortString) => {
        const sortSuffixesRegex = sortString.match(
            /.*(?:(?<ascend>_ASC)|(?<descend>_DESC))/
        );
        if (sortSuffixesRegex.groups?.ascend) return "ascend";
        if (sortSuffixesRegex.groups?.descend) return "descend";
        return null;
    };

    const getDefaultSortOrderForColumn = (colIndex) => {
        const appliedSort = searchParams.get("sort") ?? "SN_ASC";
        const columnSortField = SORT_FIELDS[colIndex];
        if (!appliedSort.includes(columnSortField)) return null;
        const direction = getDirectionFromSortString(appliedSort);
        if (!direction) return null;
        return direction;
    };

    const translateSearchParamsToDefaultFilteredValues = (searchParams) => {
        const getPowerModeFilters = () => {
            const powerModeFilters = searchParams
                .getAll("powerMode")
                .map((filter) => "powerMode." + filter);
            const showBatteryWarningFilters = searchParams
                .getAll("showBatteryWarning")
                .map((filter) => "showBatteryWarning." + filter);

            return powerModeFilters.concat(showBatteryWarningFilters);
        };
        const getMostRecentUploadFilters = () => {
            const mostRecentUploadWarning = searchParams.get(
                "mostRecentUploadWarning"
            );
            if (mostRecentUploadWarning === "true")
                return ["mostRecentUploadWarning.true"];
            if (mostRecentUploadWarning === "false")
                return ["mostRecentUploadWarning.false"];
            return [];
        };
        const getStFirmwareRevisionFilters = () => {
            const revisions = searchParams.getAll("stFirmwareRevision");
            return revisions;
        };
        const getEspFirmwareRevisionFilters = () => {
            const revisions = searchParams.getAll("espFirmwareRevision");
            return revisions;
        };
        const getHardwareRevisionFilters = () => {
            return searchParams.getAll("hardwareRevision");
        };
        const getFrozenAtFilters = () => {
            const isFrozen = searchParams.get("isFrozen");
            if (isFrozen === "true") return ["isFrozen.true"];
            if (isFrozen === "false") return ["isFrozen.false"];
            return [];
        };
        const getOrphanedAtFilters = () => {
            const isOrphaned = searchParams.get("isOrphaned");
            if (isOrphaned === "true") return ["isOrphaned.true"];
            if (isOrphaned === "false") return ["isFrozen.false"];
            return [];
        };

        return {
            powerMode: getPowerModeFilters(),
            mostRecentUpload: getMostRecentUploadFilters(),
            stFirmwareRevision: getStFirmwareRevisionFilters(),
            espFirmwareRevision: getEspFirmwareRevisionFilters(),
            hardwareRevision: getHardwareRevisionFilters(),
            frozenAt: getFrozenAtFilters(),
            orphanedAt: getOrphanedAtFilters(),
        };
    };
    const defaultFilteredValuesByColumn = translateSearchParamsToDefaultFilteredValues(
        searchParams
    );
    const getDefaultFilteredValuesForColumn = (colIndex) =>
        defaultFilteredValuesByColumn[colIndex];

    const forwardTableChangeToSearchParams = (pagination, sorter) => {
        const getSort = (sorter) => {
            if (!(sorter.field && sorter.order)) {
                return undefined;
            }
            return SORT_FIELDS[sorter.field] + "_" + SORT_DIRS[sorter.order];
        };
        // const getIsFrozen = (filters) => {
        //     if (filters.includes("isFrozen.true")) return true;
        //     if (filters.includes("isFrozen.false")) return false;
        //     return undefined;
        // };
        // const getIsOrphaned = (filters) => {
        //     if (filters.includes("isOrphaned.true")) return true;
        //     if (filters.includes("isOrphaned.false")) return false;
        //     return undefined;
        // };
        // const getPowerMode = (filters) => {
        //     let powerModeSearchParams = [];
        //     if (filters.includes("powerMode.AC")) {
        //         powerModeSearchParams.push("AC");
        //     }
        //     if (filters.includes("powerMode.BATTERY")) {
        //         powerModeSearchParams.push("BATTERY");
        //     }
        //     if (
        //         filters.includes("showBatteryWarning.true") ||
        //         filters.includes("showBatteryWarning.false")
        //     ) {
        //         powerModeSearchParams.push("BATTERY");
        //     }
        //     return powerModeSearchParams;
        // };
        // const getShowBatteryWarning = (filters) => {
        //     let showBatteryWarningSearchParams = [];
        //     if (filters.includes("showBatteryWarning.true")) {
        //         showBatteryWarningSearchParams.push(true);
        //     }
        //     if (filters.includes("showBatteryWarning.false")) {
        //         showBatteryWarningSearchParams.push(false);
        //     }
        //     return showBatteryWarningSearchParams;
        // };
        // const getMostRecentUploadWarning = (filters) => {
        //     if (filters.includes("mostRecentUploadWarning.true")) return true;
        //     if (filters.includes("mostRecentUploadWarning.false")) return false;
        //     return undefined;
        // };
        // const getStFirmwareRevision = (filters) => {
        //     const selectedRevisionNumbers = filters;
        //     return selectedRevisionNumbers;
        // };
        // const getEspFirmwareRevision = (filters) => {
        //     const selectedRevisionNumbers = filters;
        //     return selectedRevisionNumbers;
        // };
        // const getHardwareRevision = (filters) => {
        //     const selectedRevisionNumbers = filters;
        //     return selectedRevisionNumbers;
        // };

        const newSearchParams = {
            ...Object.fromEntries(searchParams),
            page: pagination.current,
            pageSize: pagination.pageSize,
            sort: getSort(sorter),
            // isFrozen: getIsFrozen(filters.frozenAt ?? []),
            // isOrphaned: getIsOrphaned(filters.orphanedAt ?? []),
            // powerMode: getPowerMode(filters.power ?? []),
            // showBatteryWarning: getShowBatteryWarning(filters.power ?? []),
            // mostRecentUploadWarning: getMostRecentUploadWarning(
            //     filters.mostRecentUpload ?? []
            // ),
            // stFirmwareRevision: getStFirmwareRevision(
            //     filters.stFirmwareRevision ?? []
            // ),
            // espFirmwareRevision: getEspFirmwareRevision(
            //     filters.espFirmwareRevision ?? []
            // ),
            // hardwareRevision: getHardwareRevision(
            //     filters.hardwareRevision ?? []
            // ),
        };

        function removeUndefinedSearchParams() {
            Object.entries(newSearchParams).forEach(([key, value]) => {
                if (value === undefined) delete newSearchParams[key];
            });
        }
        removeUndefinedSearchParams();

        function resetPageToOneIfNeeded() {
            if (Number(searchParams.get("page")) !== pagination.current) return;
            newSearchParams.page = 1;
        }
        resetPageToOneIfNeeded();
        setSearchParams(newSearchParams);
    };

    const dateFormat = "YYYY-MM-DD"
    const timeFormat = "HH:mm:ss"
    const format = `${dateFormat} ${timeFormat}`

    let columns = [
        {
            title: "Monitor SN",
            dataIndex: "device_serial_nos",
            width: 200,
            key: "device_serial_nos",
            fixed: "left",
            sorter: true,
            defaultSortOrder: getDefaultSortOrderForColumn("device_serial_nos"),
            render: function renderActionButton(id, record) {
                return (
                    <ActionIconButton component={Link} to={`/cat-feeder/events/${record.id}`}>
                        <span style={{ fontSize: "14px" }}>{record.device_serial_nos}</span>
                    </ActionIconButton>
                );
            },
        },
        {
            title: "Start time",
            dataIndex: "startTime",
            key: "startTime",
            displayName: "Start time",
            // render: (rowData) => moment(rowData.startTime).format(format),
            render: (t) => <Time>{t}</Time>,
            sorter: true,
        },
        {
            title: "Tags",
            dataIndex: "tags",
            render: (rowData) =>
                !rowData ?? rowData.tags.map((tag, i) => (
                    <Chip key={`tag-${i}`} label={tag} style={{ margin: "2px" }} />
                )) ?? "",
            sorter: false
        },
        {
            title: "Event Type",
            dataIndex: "model_value",
            filterer: true,
        },
        {
            title: "Action",
            dataIndex: "id",
            key: "id",
            width: 150,
            render: function renderActionButton(id, record) {
                return (
                    <ActionIconButton component={Link} to={`/cat-feeder/events/${record.id}`}>
                        <VisibilityIcon />
                    </ActionIconButton>
                );
            },
        },
    ];

    if (columnsFn) {
        columns = columnsFn(columns);
    }
    const defaultPagination = {
        position: ["topRight", "bottomRight"],
        defaultCurrent: 1,
        defaultPageSize: 50,
        showSizeChanger: true,
        showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
    };
    const tablePagination =
        pagination === false
            ? false
            : {
                ...defaultPagination,
                ...pagination,
            };


    const [anchorEl, setAnchorEl] = React.useState(null | HTMLElement > (null));
    const [modalValueFilters, setmodalValueFilters] = React.useState([]);
    const open = Boolean(anchorEl);
    const [selectedFilters, allSelectedFilters] = React.useState([{ "name": "Cat Interaction Eating", "value": "catinteraction_eating", "checked": false },
    { "name": "Feeder Dispense", "value": "feederdispense", "checked": false },
    { "name": "Other Non Interaction", "value": "other non-interaction", "checked": false },
    { "name": "Cat Eating + Food Addition", "value": "cat_eating + food addition", "checked": false },
    { "name": "Cat Non Interaction", "value": "cat non-interaction", "checked": false },
    { "name": "PIR Trigger", "value": "pir trigger", "checked": false },
    { "name": "Human Events", "value": "human events", "checked": false }]);

    useEffect(() => {
         let updatedFilters = selectedFilters.map(item =>
            modal_value?.includes(item.value) === true ? { ...item, checked: true } : item
        );
        allSelectedFilters(updatedFilters);
    }, [])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const applyFilter = () => {
        let finalFilterObjects = selectedFilters.filter(
            (filter) => filter.checked === true
          );
        let finalFilters = finalFilterObjects.map(filter => filter.value);
        if (finalFilters.length > 0){
            const newSearchParams = {
                ...Object.fromEntries(searchParams),
                page: pagination.current,
                pageSize: pagination.pageSize,
                modal_value: finalFilters.toString()
            }
            
            setSearchParams(newSearchParams)
            setAnchorEl(null);
        }
        else{
            const newSearchParams = {
                ...Object.fromEntries(searchParams),
                page: pagination.current,
                pageSize: pagination.pageSize,
                modal_value: ""
            }
            
            setSearchParams(newSearchParams)
            setAnchorEl(null);
        }
        
    };

    const handleChange = (e) => {
        let itemName = e.target.name;
        let checked = e.target.checked;
        let updatedFilters = selectedFilters.map(item =>
            item.name === itemName ? { ...item, checked: checked } : item
        );
        allSelectedFilters(updatedFilters);
    }

    return (
        <FullWidthPaper>
            <FullWidthTableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <StyledTableCell key={column.key}>
                                    {column.filterer ? (
                                        <div>
                                            <span>{column.title}</span>
                                        <FilterAltIcon onClick={(event) => handleClick(event)} />
                                        <Menu
                                            id="basic-menu"
                                            anchorEl={anchorEl}
                                            open={open}
                                            onClose={handleClose}
                                            MenuListProps={{
                                                'aria-labelledby': 'basic-button',
                                            }}
                                        >
                                            {selectedFilters.map(item => (
                                                <MenuItem key={item.value} value={item.value}>
                                                    <Checkbox
                                                        checked={item.checked}
                                                        onChange={(e) => [handleChange(e)]}
                                                        name={item.name}
                                                        value={item.value} />{item.name}
                                                </MenuItem>
                                            ))}
                                            <Button variant="contained" color="primary" type="button" onClick={applyFilter} style={{height: '24px', width: '34.29px', marginLeft: '165px'}}>
                                                Filter
                                            </Button>
                                        </Menu> 
                                        
                                        </div>
                                        // <TableSortLabel
                                        //     active={true}
                                        //     direction={getDefaultSortOrderForColumn(column.dataIndex) || 'ascend'}
                                        //     onClick={() => forwardTableChangeToSearchParams(pagination, column.sorter)}
                                        // // onChange={forwardTableChangeToSearchParams}
                                        // >
                                        //     {column.title}
                                        // </TableSortLabel>

                                    ) : (
                                        column.title
                                    )}
                                    {/* {column.filterer ? (
                                    []
                                    )} */}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row) => (
                            <TableRow key={row.id}>
                                {columns.map((column) => (
                                    <TableCell key={column.key}>
                                        {column.render ? column.render(row[column.dataIndex], row) : row[column.dataIndex]}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {data?.length == 0 &&
                    <Typography variant="h6" style={{ marginLeft: "640px", width: "50%", padding: "10px" }}> No Data Found </Typography>
                }
            </FullWidthTableContainer>
            {pagination !== false && (
                <CustomTablePagination
                    count={data.length}
                    rowsPerPage={pagination.pageSize}
                    page={pagination.current - 1}
                    onPageChange={(event, newPage) => forwardTableChangeToSearchParams({ current: newPage + 1, pageSize: pagination.pageSize }, {}, {})}
                    onRowsPerPageChange={(event) => forwardTableChangeToSearchParams({ current: 1, pageSize: parseInt(event.target.value, 10) }, {}, {})}
                    rowsPerPageOptions={[10, 25, 50]}
                    labelRowsPerPage="View"
                    labelDisplayedRows={({ from, to, count }) => (
                        <Box display="flex" alignItems="center">
                            <Box>{`Found ${count.toLocaleString()} Records`}</Box>
                            <Box display="flex" alignItems="center" mx={2}>
                                <WatchLaterIcon fontSize="small" />
                                {/* <Box mx={1}>{`${from} - ${to}`}</Box> */}
                                <Box mx={1}>{`${from} - ${Math.ceil(from + 49)}`}</Box>
                            </Box>
                        </Box>
                    )}
                    pagination={pagination}
                />
            )}
        </FullWidthPaper>
    );
};

export default CatFeederEventTable;
