import React, { useState, useEffect } from "react";
import "./CatFeederEvents.css";
import { Card, CardContent, Grid, IconButton } from "@mui/material";
import DeviceSnSuffixSearch from "../../../utils/DeviceSnSuffixSearch";
import { useSearchParams } from "../../../utils/useSearchParams";
import axiosInstance from "../../../api/axios/axiosInstance";
import LoadingSpinner from "../../LoadingSpinner/component";
import CatFeederEventTable from "../../Table/CatFeederEventsTable/CatFeederEventsTable";
// import EventFilterPopover from "../../../utils/EventFilterPopover";
// import FilterAltIcon from "@mui/icons-material/FilterAlt";

const CatFeederEventsHome = () => {
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [newData, setNewData] = React.useState();
  const [newDataRevision, setNewDataRevision] = React.useState();
  const [error, setError] = useState('');
  const [searchParams, setSearchParams] = useSearchParams({
    page: 1,
    pageSize: 50,
    snSuffix: "",
    modal_value: ""
  })
  const [isLoading, setisLoading] = React.useState(true);


  


  useEffect(() => {
    setisLoading(true);
    let withTags = ""
    let withoutTags = ""

    
    
    let url = "/get-cat-feeder-timeseries-events"
    
    axiosInstance.post(url, {
      count: 50,
      snSuffixSessionID: searchParams.get("snSuffix"),
      page: searchParams.get("page"),
      modal_value: searchParams.get("modal_value")
    },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response, error) => {
        setisLoading(false);
        setNewData(response.data);
        setError(error);
      }).catch((error) => {
        setisLoading(false);
      })



  }, [searchParams])

  const handleSearch = (newSnSuffix) => {
    let newSearchParams = {
      ...Object.fromEntries(searchParams),
      page: 1,
      snSuffix: newSnSuffix.trim(),
    }
    if (newSnSuffix === "") {
      delete newSearchParams["snSuffix"]
      
    }
    setisLoading(true)
    setSearchParams(newSearchParams);
  };

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleFilterApply = (appliedFilters) => {
    setSearchParams(appliedFilters)
    setFilters(appliedFilters);
    setFilterAnchorEl(null);
    // Add logic to apply the filters to your data fetching logic
  };

  const pagination = {
    current: searchParams.get("page")
      ? Number(searchParams.get("page"))
      : 0,
    pageSize: searchParams.get("pageSize")
      ? Number(searchParams.get("pageSize"))
      : 0,
    onChange: (page, pageSize) => {
      setSearchParams({
        ...Object.fromEntries(searchParams),
        page,
        pageSize,
      })
    },
    total: parseInt(newData?.pageInfo.totalCount ?? 0),
  }

  const dataTransformedForTable = newData?.data.map((monitor) => {
    return {
      ...monitor,
      key: monitor.id,
      startTime: monitor.startTime ?? ""
    }
  }) ?? []


  const [filters, setFilters] = useState({});
  return !isLoading ? (
    <div className="module-content">
      <h5>Events</h5>
      <Card>
        <CardContent>
          <Grid container alignItems="center">
            <Grid item xs>
              <DeviceSnSuffixSearch onSearch={handleSearch} defaultValue={searchParams.get("snSuffix") ?? ""} placeholder="Input Event ID" />
            </Grid>
            {/* <Grid item>
              <IconButton onClick={handleFilterClick}>
                <FilterAltIcon />
              </IconButton>
            </Grid> */}
            
          </Grid>
          <br></br>

          <Grid container>
            <CatFeederEventTable
              data={dataTransformedForTable}
              loading={false}
              pagination={pagination}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          </Grid>
        </CardContent>
      </Card>
      {/* <EventFilterPopover
        filterOptions={filterOptions}
        anchorEl={filterAnchorEl}
        handleClose={handleFilterClose}
        handleApply={handleFilterApply}
      /> */}
    </div>
  ) : (
    <LoadingSpinner />
  );
};

export default CatFeederEventsHome;
