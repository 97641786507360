import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import { CircularProgress, Checkbox, FormControlLabel, Typography, Box, useTheme, Grid, BoxProps } from "@mui/material";
import {
    Brush,
    CartesianGrid,
    Label,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import axiosInstance from "./../../api/axios/axiosInstance";
import { useTimezone } from "./../../utils/context/Timezone";

const defaultTareValues = [0, 0, 0, 0];

const createLoadCellData = (
    data,
    startTime,
    sampleRate,
    timezone,
    tare = defaultTareValues,
) => {
    const time = moment(startTime + "Z").tz(timezone);
    return data.map((datum, i) => {
        const timestamp = time
            .clone()
            .add(i / sampleRate, "seconds")
            .format("HH:mm:ss");
        const d0 = datum[0] - tare[0];
        const d1 = datum[1] - tare[1];
        const d2 = datum[2] - tare[2];
        const d3 = datum[3] - tare[3];
        return {
            time: time.valueOf(),
            a: d0,
            b: d1,
            c: d2,
            d: d3,
            total: d0 + d1 + d2 + d3,
            timestamp,
        };
    });
};

const FrankenCatBowlLoadCellChart2View = ({ eventId, startTime, source }) => {
    const theme = useTheme();
    const timezone = useTimezone();
    const [applyTareWeight, setApplyTareWeight] = useState(true);
    const [gyroApplyTareWeight, setgyroApplyTareWeight] = useState(true);
    const [newData, setNewData] = useState(null);
    const [loadCellData, setLoadCellData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [loadCellStatus, setLoadCellStatus] = useState("");

    const sDate = startTime ? moment(startTime).format("YYYY-MM-DD HH:mm:ss") : null;
    const url = `/get-franken-cat-bowl-loadcelldata?page=1&event_id=${eventId}&source=${source}`;

    useEffect(() => {
        axiosInstance.get(url)
            .then((response) => {
                setNewData(response.data.data);
                setLoadCellData(response.data.data.loadCellData);
                setLoadCellStatus(response.data.data.data_status)
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
            });
    }, [eventId]);

    if (isLoading) {
        return <CircularProgress />;
    }
    if (loadCellStatus === "Load Cell Data not Available") {
        return (
            <Typography variant="body2" color="textSecondary" style={{textAlign: "center", verticalAlign: "middle"}}>
                No Sensor Data available.
            </Typography>
        );
    }


    function Item(props: BoxProps) {
        const { sx, ...other } = props;
        return (
          <Box
            sx={[
              (theme) => ({
                bgcolor: '#fff',
                color: 'grey.800',
                border: '1px solid',
                borderColor: 'grey.300',
                p: 1,
                borderRadius: 2,
                fontSize: '0.875rem',
                fontWeight: '700',
                ...theme.applyStyles('dark', {
                  bgcolor: '#101010',
                  color: 'grey.300',
                  borderColor: 'grey.800',
                }),
              }),
              ...(Array.isArray(sx) ? sx : [sx]),
            ]}
            {...other}
          />
        );
      }

    return (
        <div style={{ width: '100%' }}>
        <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(2, 1fr)' }}>
        {loadCellData.ld1_data.length > 0 ?
         <Box sx={{ height: 400 }}>
        
             <label htmlFor="apply-tare-weight">
             Light Detection
             </label><br></br>
            
             <ResponsiveContainer>
                 <LineChart
                    margin={{ left: 10, right: 10 }}
                    data={createLoadCellData(
                        loadCellData.ld1_data,
                        loadCellData.startTime,
                        loadCellData.sampleRate,
                        timezone,
                        applyTareWeight && loadCellData.ld1_data
                            ? loadCellData.ld1_tare
                            : defaultTareValues,
                        "ld1"
                    )}
                >
                    <XAxis dataKey="timestamp" />
                    <YAxis yAxisId="left">
                        <Label
                            angle={270}
                            position="left"
                            style={{ textAnchor: "middle" }}
                        >
                            Individual
                        </Label>
                    </YAxis>
                    <YAxis dataKey="total" yAxisId="right" orientation="right">
                        <Label
                            angle={270}
                            position="right"
                            style={{ textAnchor: "middle" }}
                        >
                            Total
                        </Label>
                    </YAxis>
                    <CartesianGrid stroke={theme.palette.divider} />
                    <Tooltip />
                    <Brush />
                    <Line
                        type="monotone"
                        key="a"
                        dataKey="a"
                        stroke={theme.palette.primary.main}
                        strokeWidth={1}
                        yAxisId="left"
                        dot={false}
                    />
                </LineChart>
            </ResponsiveContainer>
           
        </Box>
         :
         <Typography variant="body2" color="textSecondary" style={{ textAlign: "center", verticalAlign: "middle" }}>
             No Light Detection Data available.
         </Typography>
     }
     {loadCellData.ldr1_data.length > 0 ?
         <Box sx={{ height: 400, marginTop:"-24px"}}>
             <br></br>
             
             <label htmlFor="apply-tare-weight">
             Light Dependant Resistor
             </label><br></br>
            
             <ResponsiveContainer>
                 <LineChart
                    margin={{ left: 10, right: 10 }}
                    data={createLoadCellData(
                        loadCellData.ldr1_data,
                        loadCellData.startTime,
                        loadCellData.sampleRate,
                        timezone,
                        gyroApplyTareWeight && loadCellData.ldr1_tare
                            ? loadCellData.ldr1_tare
                            : defaultTareValues,
                        "ldr1"
                    )}
                >
                    <XAxis dataKey="timestamp" />
                    <YAxis yAxisId="left">
                        <Label
                            angle={270}
                            position="left"
                            style={{ textAnchor: "middle" }}
                        >
                            Individual
                        </Label>
                    </YAxis>
                    <YAxis dataKey="total" yAxisId="right" orientation="right">
                        <Label
                            angle={270}
                            position="right"
                            style={{ textAnchor: "middle" }}
                        >
                            Total
                        </Label>
                    </YAxis>
                    <CartesianGrid stroke={theme.palette.divider} />
                    <Tooltip />
                    <Brush />
                    <Line
                        type="monotone"
                        key="a"
                        dataKey="a"
                        stroke={theme.palette.primary.main}
                        strokeWidth={1}
                        yAxisId="left"
                        dot={false}
                    />
                    

                </LineChart>
            </ResponsiveContainer>
            
        </Box>
        :
        <Typography variant="body2" color="textSecondary" style={{ textAlign: "center", verticalAlign: "middle" }}>
            No Light Dependant Resistor Data available.
        </Typography>
    }
     {loadCellData.pir_data.length > 0 ?
        <Box sx={{ height: 400, marginTop: '20px'}}>
           
             <label htmlFor="apply-tare-weight">
             Passive Infra-Red
             </label><br></br>
            
             <ResponsiveContainer>
                 <LineChart
                    margin={{ left: 10, right: 10 }}
                    data={createLoadCellData(
                        loadCellData.pir_data,
                        loadCellData.startTime,
                        loadCellData.sampleRate,
                        timezone,
                        applyTareWeight && loadCellData.pir_tare
                            ? loadCellData.pir_tare
                            : defaultTareValues,
                        "irt"
                    )}
                >
                    <XAxis dataKey="timestamp" />
                    <YAxis yAxisId="left">
                        <Label
                            angle={270}
                            position="left"
                            style={{ textAnchor: "middle" }}
                        >
                            Individual
                        </Label>
                    </YAxis>
                    <YAxis dataKey="total" yAxisId="right" orientation="right">
                        <Label
                            angle={270}
                            position="right"
                            style={{ textAnchor: "middle" }}
                        >
                            Total
                        </Label>
                    </YAxis>
                    <CartesianGrid stroke={theme.palette.divider} />
                    <Tooltip />
                    <Brush />
                    <Line
                        type="monotone"
                        key="a"
                        dataKey="a"
                        stroke={theme.palette.primary.main}
                        strokeWidth={1}
                        yAxisId="left"
                        dot={false}
                    />
                    
                </LineChart>
            </ResponsiveContainer>
           
        </Box>
         :
         <Typography variant="body2" color="textSecondary" style={{ textAlign: "center", verticalAlign: "middle" }}>
             No Passive Infra-Red Data available.
         </Typography>
     }
     {loadCellData.tmp_data.length > 0 ?
         <Box sx={{ height: 400 }}>
             <br></br>
             
             <label htmlFor="apply-tare-weight">
                 Temperature
             </label><br></br>
            
             <ResponsiveContainer>
                 <LineChart
                    margin={{ left: 10, right: 10 }}
                    data={createLoadCellData(
                        loadCellData.tmp_data,
                        loadCellData.startTime,
                        loadCellData.sampleRate,
                        timezone,
                        gyroApplyTareWeight && loadCellData.tmp_tare
                            ? loadCellData.tmp_tare
                            : defaultTareValues,
                        "tmp"
                    )}
                >
                    <XAxis dataKey="timestamp" />
                    <YAxis yAxisId="left">
                        <Label
                            angle={270}
                            position="left"
                            style={{ textAnchor: "middle" }}
                        >
                            Individual
                        </Label>
                    </YAxis>
                    <YAxis dataKey="total" yAxisId="right" orientation="right">
                        <Label
                            angle={270}
                            position="right"
                            style={{ textAnchor: "middle" }}
                        >
                            Total
                        </Label>
                    </YAxis>
                    <CartesianGrid stroke={theme.palette.divider} />
                    <Tooltip />
                    <Brush />
                    <Line
                        type="monotone"
                        key="a"
                        dataKey="a"
                        stroke={theme.palette.primary.main}
                        strokeWidth={1}
                        yAxisId="left"
                        dot={false}
                    />

                </LineChart>
            </ResponsiveContainer>
            
        </Box>
        :
        <Typography variant="body2" color="textSecondary" style={{ textAlign: "center", verticalAlign: "middle" }}>
            No Temperature Data available.
        </Typography>
    }
        </Box>
      </div>
        

    );
};

export default FrankenCatBowlLoadCellChart2View;
