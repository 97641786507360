import React from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
    IconButton,
    Paper,
    TablePagination,
    Typography,
    Popover,
    Box,
    Select,
    MenuItem,
} from "@mui/material";
import { styled } from "@mui/system";
import { Link, useSearchParams } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import InfoCircleOutlined from "@mui/icons-material/InfoOutlined";
import moment from "moment";
import PowerStatus from "../../Modules/PowerStatus/PowerStatus";
import Highlighter from "react-highlight-words";
import { findChunksAtEndOfString } from "../../../utils/findChunksAtEndOfString";
import TableCellWifiRssi from "../../Modules/TableCellWifiRssi/TableCellWifiRssi";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import TimeSinceDate from "../../TimeSinceDate/component";

const CustomTableContainer = styled(TableContainer)({
    width: '100%',
});

const ActionIconButton = styled(IconButton)({
    padding: 0,
});

const CustomPaper = styled(Paper)({
    width: '100%',
    overflowX: 'auto',
});

const CustomTableCell = styled(TableCell)({
    fontWeight: 'bold',
    backgroundColor: '#f5f5f5',
});

const CustomPagination = styled(Box)({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '10px',
    borderTop: '1px solid #e0e0e0',
    backgroundColor: '#fafafa',
});

const CustomTablePagination = (props) => {
    const {
        count,
        page,
        rowsPerPage,
        onPageChange,
        onRowsPerPageChange,
        rowsPerPageOptions,
        labelDisplayedRows,
        labelRowsPerPage,
        pagination
    } = props;

    return (
        <CustomPagination>
            <Box display="flex" alignItems="center">
                <Box>{labelRowsPerPage}</Box>
                <Select
                    value={rowsPerPage}
                    onChange={onRowsPerPageChange}
                    variant="outlined"
                    size="small"
                    style={{ marginLeft: 8, marginRight: 16 }}
                >
                    {rowsPerPageOptions.map((rowsPerPageOption) => (
                        <MenuItem key={rowsPerPageOption} value={rowsPerPageOption}>
                            {rowsPerPageOption}
                        </MenuItem>
                    ))}
                </Select>
                <Box>{labelDisplayedRows({ from: page * rowsPerPage + 1, to: Math.min(count, (page + 1) * rowsPerPage), count })}</Box>
            </Box>
            <Box display="flex" alignItems="center" marginLeft={2}>
                <IconButton
                    onClick={(event) => onPageChange(event, page - 1)}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    <ArrowBackIosIcon />
                </IconButton>
                <IconButton
                    onClick={(event) => onPageChange(event, page + 1)}
                    disabled={page >= Math.ceil(pagination.total / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    <ArrowForwardIosIcon />
                </IconButton>
            </Box>
        </CustomPagination>
    );
};

const SORT_FIELDS = {
    mostRecentUpload: "LATEST_EVENT"
};

const SORT_DIRS = {
    ascend: "asc",
    descend: "desc",
};

const CatFeederTable = ({
    data = [],
    loading = false,
    columnsFn,
    pagination,
    updateParentState,
    isupdateTable,
    ...props
}) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('mostRecentUpload');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        const newSearchParams = {
            ...Object.fromEntries(searchParams),
            page: pagination.current,
            pageSize: pagination.pageSize,
            sort: order
        };
        setSearchParams(new URLSearchParams(newSearchParams));
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    const getDirectionFromSortString = (sortString) => {
        const sortSuffixesRegex = sortString.match(
            /.*(?:(?<ascend>_ASC)|(?<descend>_DESC))/
        );
        if (sortSuffixesRegex.groups?.ascend) return "asc";
        if (sortSuffixesRegex.groups?.descend) return "desc";
        return null;
    };

    const getDefaultSortOrderForColumn = (colIndex) => {
        const appliedSort = searchParams.get("sort") ?? "LATEST_EVENT_ASC";
        const columnSortField = SORT_FIELDS[colIndex];
        if (!appliedSort.includes(columnSortField)) return null;
        const direction = getDirectionFromSortString(appliedSort);
        if (!direction) return null;
        return direction;
    };

    const translateSearchParamsToDefaultFilteredValues = (searchParams) => {
        const getTrainedStateFilters = () => {
            return searchParams.getAll("trainedState");
        };
        return {
            trainedState: getTrainedStateFilters(),
        };
    };
    const defaultFilteredValuesByColumn = translateSearchParamsToDefaultFilteredValues(searchParams);
    const getDefaultFilteredValuesForColumn = (colIndex) =>
        defaultFilteredValuesByColumn[colIndex];

    const forwardTableChangeToSearchParams = (pagination, sorter) => {

        const getSort = (sorter) => {
            if (!(sorter.field && sorter.order)) {
                return undefined;
            }
            return SORT_FIELDS[sorter.field] + "_" + SORT_DIRS[sorter.order];
        };

        const newSearchParams = {
            ...Object.fromEntries(searchParams),
            page: pagination.current,
            pageSize: pagination.pageSize,
            sort: getSort(sorter)
        };

        function removeUndefinedSearchParams() {
            Object.entries(newSearchParams).forEach(([key, value]) => {
                if (value === undefined) delete newSearchParams[key];
            });
        }
        removeUndefinedSearchParams();

        function resetPageToOneIfNeeded() {
            if (Number(searchParams.get("page")) !== pagination.current) return;
            newSearchParams.page = 1;
        }
        resetPageToOneIfNeeded();

        setSearchParams(new URLSearchParams(newSearchParams));
    };

    let columns = [
        {
            title: "Serial No.",
            dataIndex: "sn",
            width: 200,
            render: function MachineSerial(serial, record) {
                return (
                    <div style={{ display: "flex" }}>
                        <Link to={`/cat-feeder/monitors/${record.id}`}>
                            <Highlighter
                                searchWords={[searchParams.get("snSuffix")]}
                                textToHighlight={serial ?? ""}
                                findChunks={findChunksAtEndOfString}
                                highlightStyle={{ backgroundColor: "#ffe58f" }}
                            />
                        </Link>

                    </div>
                );
            },
            fixed: "left"
        },
        {
            title: "Name",
            dataIndex: "name"
        },
        {
            title: "Latest Event",
            dataIndex: "mostRecentUpload",

            sorter: true,
            defaultSortOrder: getDefaultSortOrderForColumn("mostRecentUpload"),
            render: function LastUpload(_, record) {
                return record.mostRecentUploadAt ? (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ marginRight: "4px" }}>
                            {record.mostRecentUploadAt}
                        </div>
                        {record.mostRecentUploadWarning ? (
                            <Tooltip
                                title={
                                    "Has not been seen in expected time range"
                                }
                            >
                                <VisibilityIcon fontSize="small" />
                            </Tooltip>
                        ) : null}
                    </div>
                ) : (
                    "Never"
                )

            },
            defaultSortOrder: getDefaultSortOrderForColumn("mostRecentUpload"),
        },
        {
            title: "Last Upload",
            dataIndex: "mostRecentUpload",
            width: 140,
            render: function LastUpload(_, record) {
                return record.mostRecentUploadAt ? (
                    <Box display="flex" alignItems="center">
                        <Box mr={1}>
                            <TimeSinceDate
                                dateString={record.mostRecentUploadAt}
                            />
                        </Box>
                        {record.mostRecentUploadWarning && (
                            <Tooltip title="Has not been seen in expected time range">
                                <VisibilityIcon fontSize="small" />
                            </Tooltip>
                        )}
                    </Box>
                ) : (
                    "Never"
                );
            }
        },
        {
            title: "Power",
            dataIndex: "power",
            render: function PowerStatusColumn(_, record) {
                return (
                    <Box>
                        <PowerStatus
                            powerMode={record.powerMode}
                        // batteryPercentage={record.batteryPercentage}
                        // showBatteryWarning={record.showBatteryWarning}
                        />
                    </Box>
                )
            }
        },
        {
            title: "Firmware Version",
            width: 85,
            dataIndex: "firmwareVersion",
            render: (firmwareVersion) => firmwareVersion ?? "-",

        },
        {
            title: "Firmware Version Update Date",
            dataIndex: "firmwareVersion_change_date",
            width: 140,
            render: function LastUpload(_, record) {
                return record.firmwareVersion_change_date ? (
                    <Box display="flex" alignItems="center">
                        <Box mr={1}>
                            <TimeSinceDate
                                dateString={record.firmwareVersion_change_date}
                            />
                        </Box>
                        {record.firmwareVersion_change_date && (
                            <Tooltip title="Has not been seen in expected time range">

                            </Tooltip>
                        )}
                    </Box>
                ) : (
                    "Never"
                );
            }
        },

    ];

    if (columnsFn) {
        columns = columnsFn(columns);
    }

    const defaultPagination = {
        position: ["topRight", "bottomRight"],
        defaultCurrent: 1,
        defaultPageSize: 50,
        showSizeChanger: true,
        showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
    };
    const tablePagination =
        pagination === false
            ? false
            : {
                ...defaultPagination,
                ...pagination,
            };



    


    return (
        <CustomPaper>
            <CustomTableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <CustomTableCell key={column.key}>
                                    {column.sorter ? (
                                        <TableSortLabel
                                            active={orderBy === column.dataIndex}
                                            direction={orderBy === column.dataIndex ? order : 'asc'}
                                            onClick={createSortHandler(column.dataIndex)}
                                        // active={column.key === 'latestEvent'}
                                        // direction={getDefaultSortOrderForColumn(column.key) || 'asc'}
                                        // // onClick={() => getDefaultSortOrderForColumn(column.key)}
                                        // onClick={() => getDefaultSortOrderForColumn('mostRecentUpload')}
                                        >
                                            {column.title}
                                        </TableSortLabel>
                                    ) : (
                                        column.title
                                    )}
                                </CustomTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row) => (
                            <TableRow key={row.id}>
                                {columns.map((column) => (
                                    <TableCell key={column.key}>
                                        {column.render ? column.render(row[column.dataIndex], row) : row[column.dataIndex]}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {data?.length == 0 &&
                    <Typography variant="h6" style={{ marginLeft: "640px", width: "50%", padding: "10px" }}> No Data Found </Typography>
                }
            </CustomTableContainer>
            {pagination !== false && (
                <CustomTablePagination
                    component="div"
                    count={data.length}
                    rowsPerPage={pagination.pageSize}
                    page={pagination.current - 1}
                    onPageChange={(event, newPage) => forwardTableChangeToSearchParams({ current: newPage + 1, pageSize: pagination.pageSize }, {}, {})}
                    onRowsPerPageChange={(event) => forwardTableChangeToSearchParams({ current: 1, pageSize: parseInt(event.target.value, 10) }, {}, {})}
                    rowsPerPageOptions={[10, 25, 50]}
                    labelRowsPerPage="View"
                    labelDisplayedRows={({ from, to, count }) => (
                        <Box display="flex" alignItems="center">
                            <Box>{`Found ${count.toLocaleString()} Records`}</Box>
                            <Box display="flex" alignItems="center" mx={2}>
                                <VisibilityIcon fontSize="small" />
                                {/* <Box mx={1}>{`${from} - ${to}`}</Box> */}
                                <Box mx={1}>{`${from} - ${Math.ceil(from + 49)}`}</Box>
                            </Box>
                        </Box>
                    )}
                    pagination={pagination}
                />
                // </CustomTablePagination>
            )}
        </CustomPaper>
    );
};

export default CatFeederTable;
